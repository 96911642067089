<!-- eslint-disable max-len -->
<template>
  <div id="modalOnboardingAppStart" class="c-modal modal" style="z-index: 99;">
    <div class="c-section--content-title-before">
      <h2 class="text-center"></h2>
    </div>

    <div class="c-modal--img text-center">
      <SliderOnboardingStart />
    </div>

    <div class="c-nav c-content--button-skip">
      <button
        @click="close()"
        class="c-button c-button--primary c-button--width-fuid c-button--flat c-button--mobile-small waves-effect c-modal--button modal-close"
      >
        De acuerdo
      </button>
    </div>
  </div>
</template>

<script>
import SliderOnboardingStart from "../General/SliderOnboardingStart.vue";

export default {
  components: { SliderOnboardingStart },
  name: "ModalStartSlider",
  methods: {
    close() {
      const modalOnboardingAppStart = document.getElementById(
        "modalOnboardingAppStart"
      );
      modalOnboardingAppStart.style.display = "none";
      // localStorage.setItem("alreadyShowModalStart", true);
    },
  },
};
</script>

<style scoped>

#modalOnboardingAppStart{
  width:40%;
}

@media screen and (max-width: 1200px) {
  #modalOnboardingAppStart{
    width:60%;
  }
}

@media screen and (max-width: 992px) {
  #modalOnboardingAppStart{
    width:90%;
  }
}

@media screen and (max-width: 600px) {
  #modalOnboardingAppStart{
    width:100%;
  }

  .c-button--mobile-small{
    font-size: 14px;
      line-height: 13px;
      padding: 0.6em 1em;
  }
}

.c-slider::before {
  background-color: #fafafa;
}
.c-button--flat {
  background-color: #ff565d;
}
.c-content--button-skip button {
  color: #fafafa !important;
}
.c-slider_item div {
  font-size: 19px !important;
}
</style>
