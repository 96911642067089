<template>
  <div class="modal c-modal" :id="id">
    <!-- <div class="c-modal--directions-title">
      <h2>Seleccione programación de recojo</h2>
    </div> -->
    <div class='text-2xl-bold' style='padding: 20px 20px 0 20px;'>Seleccione programación de recojo</div>
    <div class="c-modal--directions-body">
      <div>
        <app-calendar @getDate="getDataCalendar"></app-calendar>
      </div>
    </div>
    <div class="c-modal__footer-buttons c-nav m-1">
      <ButtonDefinitive
        label="Guardar"
        @click='closeThisModal'
      />
    </div>
  </div>
</template>

<script>
import AppCalendar from "../General/AppCalendar.vue";
import ButtonDefinitive from '@/components/Button/ButtonDefinitive.vue'

export default {
  components: {
    AppCalendar,
    ButtonDefinitive,
  },
  name: "ModalSelectDate",
  props: {
    id: String,
  },
  data() {
    return {
      date: "",
    };
  },
  methods: {
    getDataCalendar(date) {
      this.$store.dispatch("PROGRAMMED_ORDER_DELIVER_DATE", date);
    },
    save() {},
    closeThisModal() {
      this.closeModal(this.id)
    }
  },
  computed: {},
  mounted() {},
};
</script>

<style></style>
