<!-- eslint-disable max-len -->
<template>
  <div
    :id="id"
    class="c-modal modal"
    tabindex="0"
    style="
      z-index: 1003;
      display: none;
      opacity: 0;
      top: 4%;
      transform: scaleX(0.8) scaleY(0.8);
    "
  >
    <div class="c-modal--directions-title"><h2>Propina</h2></div>
    <a href="javascript:void(0)" @click="amount = 0.5"
      ><div class="c-content--file">
        <div class="c-section--content-file c-content--before">
          <div class="file-one">S/. 0.50</div>
          <div class="file-two">
            <img src="~@/assets/img/icon/icon-arrow-right.svg" alt="" />
          </div>
        </div></div></a
    ><a href="javascript:void(0)" @click="amount = 1.0"
      ><div class="c-content--file">
        <div class="c-section--content-file c-content--before">
          <div class="file-one">S/. 1.00</div>
          <div class="file-two">
            <img src="~@/assets/img/icon/icon-arrow-right.svg" alt="" />
          </div>
        </div></div></a
    ><a href="javascript:void(0)" @click="amount = 2.0"
      ><div class="c-content--file">
        <div class="c-section--content-file c-content--before">
          <div class="file-one">S/. 2.00</div>
          <div class="file-two">
            <img src="~@/assets/img/icon/icon-arrow-right.svg" alt="" />
          </div>
        </div></div></a
    ><a href="javascript:void(0)" @click="amount = 3.0"
      ><div class="c-content--file">
        <div class="c-section--content-file c-content--before">
          <div class="file-one">S/. 3.00</div>
          <div class="file-two">
            <img src="~@/assets/img/icon/icon-arrow-right.svg" alt="" />
          </div>
        </div></div
    ></a>
    <div class="c-content--file">
      <div class="c-input input-field curso-pointer">
        <input
          type="text"
          v-model="amount"
          id="amount"
          class="curso-pointer"
          @keypress="onlyNumbers"
        />
        <label for="">Ingresa un monto en soles</label>
      </div>
    </div>
    <div class="c-modal__footer-buttons c-nav m-1">
      <button
        class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect"
        @click="save"
      >
        Guardar
      </button>
      <button
        class="c-button c-button--primary c-button--width-fuid c-button--flat c-button--mobile-small waves-effect modal-close"
      >
        Cancelar
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalTip",
  props: {
    id: String,
  },
  data() {
    return {
      amount: 0,
    };
  },
  methods: {
    save() {
      this.$emit("getSelectedTip", Number(this.amount));
      this.closeModal(this.id);
    },
  },
};
</script>

<style></style>
